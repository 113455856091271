import React, { useState, useEffect, useRef } from "react";
import '../assets/eventbox.css';
import FirestoreModel from '../firestoreModel.js'
import PageTitle from '../components/pagetitle.js'
import DisplayOptions from '../components/displayoptions.js'
import DisplayFilters from '../components/displayfilters.js'
import AthleteEventSignUp from "../utils/athletesignup.js";
import DisplayOptionsContext from "../components/DisplayOptionsContext.js"

import '../assets/reportfilters.css'

const EventListView = function (props) {

    let [displayOptions, setDisplayOptions] = useState(
        {
            athleteOut: false,
            paticipatingEventsOnly: false,
            showConferenceId: false,
            athleteTotals: true,
            printerFriendly: false,
            switchNames: false,
            conferenceOrder: false,
            showPRs: false,
            showComments: false
        }
    )


    const store = {
        displayOptionsStore: [displayOptions, setDisplayOptions]
    }

    return <DisplayOptionsContext.Provider value={store}><EventList {...props} /></DisplayOptionsContext.Provider>
}

const EventList = function (props) {
    const pageData = useRef({ isFetching: true });
    let [isFetching, setIsFetching] = useState(true);
    let [objData, setObjData] = useState([]);

    const [displayOptions, setDisplayOptions] = React.useContext(DisplayOptionsContext).displayOptionsStore

    let maxRows = 39;
    /*
        let displayChange = (arg) => {
            //if (arg.athleteOut !== undefined)
            //this.setState({ displayOptions: arg });
            //else {
            const team = team;
            team.Divisions = arg.ageGroups;
            //this.setState({ team: team });
            //}
        }*/

    let displayChange = (arg) => {
        if (arg.athleteOut !== undefined)
            setDisplayOptions(arg);
        else {
            const newTeam = objData.team;
            newTeam.Divisions = arg.ageGroups;
            setObjData({ ...objData, team: newTeam });
        }

    }



    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        pageData.current.events = [];

        let teamId = ""
        pageData.current.meetId = ""


        if (!(props.location === undefined || props.location === null))
            if (!(props.location.state === undefined || props.location.state === null)) {
                teamId = props.location.state.teamId;
                pageData.current.meetId = props.location.state.meetId;
            }

        if (params.get('Team') !== null)
            teamId = params.get('Team');

        if (params.get('Meet') !== null)
            pageData.current.meetId = params.get('Meet');

        const fireModel = new FirestoreModel();

        let events = [];
        let athleteList = [];
        let outList = [];
        let notSignedUp = [];
        let athleteSignUps = [];
        let team = {};
        let meet = {};
        let qualifiers = {};

        console.log({ teamId: teamId, meetId: pageData.current.meetId })

        if (teamId !== '' && pageData.current.meetId !== '') {
            Promise.all(
                [
                    fireModel.fetchMeetTeamTagsAsync(teamId, pageData.current.meetId),
                    fireModel.fetchMeetTeamEventSignupsAsync(teamId, pageData.current.meetId),
                    //fireModel.fetchAllEventsAsync(),
                    fireModel.fetchPublicTeamList(teamId),
                    fireModel.fetchTeamAsync(teamId),
                    fireModel.fetchMeetAsync(pageData.current.meetId),
                    fireModel.fetchAllQualifiers()
                ]
            ).then((values) => {
                notSignedUp = values[0].NotSignedUp || [];
                outList = values[0].OutList;
                athleteList = values[2];
                athleteSignUps = values[1].athleteSignUps;

                team = values[3];
                meet = values[4];
                qualifiers = values[5];

                Promise.all([
                    fireModel.fetchDefaultTeamEvents(team)
                ]
                ).then((values) => {
                    const eventsPossibleDupe = values[0];

                    // get the default event list only for  those age groups that dont have specified AgeEvents
                    console.log({ eventsPossibleDupe });
                    console.log({ obj: team.AgeEvents });
                    eventsPossibleDupe.forEach((item) => {
                        if (team.AgeEvents.find((e) => e.DivisionId === item.DivisionId) === undefined) {
                            if (events.filter((e) => e.EventId === item.EventId).length === 0) {
                                events.push(item);
                            }

                        }
                    })

                    // Now add events from the teams age groups
                    team.AgeEvents.forEach((item) => {
                        if (events.filter((e) => e.EventId === item.EventId).length === 0) {
                            events.push(item);
                        }
                    }
                    )


                    events.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1)

                    team.Divisions.map((e) => { e.checked = true; return e; })

                    // Create a new attribute in events summing the number of athletes
                    events.forEach(function (event) {
                        var numberAthlete = 0;
                        for (let n = 0; n < athleteSignUps.length; n++) {
                            if (athleteSignUps[n].EventId == event.EventId)
                                numberAthlete++;
                        }
                        event.numberAthletes = numberAthlete;

                    }
                    );


                    events.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1)

                    team.Divisions.map((e) => { e.checked = true; return e; })

                    // there is a chance that the PR were updated since the sign-ups were made
                    // latest is always in the public team, this is passed down and is picked as needed inthe event box

                    // Create a new attribute in events summing the number of athletes
                    let queryDate = { notSignedUp, outList, athleteList, athleteSignUps, events, teamId: teamId, meetId: pageData.current.meetId, meet, qualifiers };
                    console.log(queryDate);
                    setObjData({ ...queryDate, isFetching: false, team: team });
                    setIsFetching(false);

                })
            })


        }

    }, [props.location])

    if (isFetching === true)
        return <div>Loading Please Wait....
            <p>If data does not load in 2 seconds please go back and reselect the meet and team then retry.</p>
        </div>
    else {
        const showOuts = displayOptions.athleteOut;

        let filteredAthletes = objData.athleteList.filter((athlete) => {
            if (objData.team.Divisions.filter((ageGroup) => ageGroup.checked === true && ageGroup.AgeGroupId === athlete.AgeGroupId).length > 0) {
                if (objData.outList !== undefined && objData.outList.includes(athlete.AthleteId) === true && showOuts === false)
                    return null;
                else
                    return athlete;
            }
            else {
                return null;
            }

        });


        let filteredEvents = objData.events;
        let athleteSignUps = objData.athleteSignUps;

        console.log({ filteredAthletes, athleteSignUps });

        let filteredSignUps = athleteSignUps.filter((aes) => {
            return filteredAthletes.filter((e) => e.AthleteId === aes.AthleteId && aes.SignedUp == true).length > 0;
        })

        let largestSizeOfEvent = 0;

        if (displayOptions.paticipatingEventsOnly === true) {
            filteredEvents = filteredEvents.filter((e) => { return e.numberAthletes > 0 ? e : null; });
        }

        // get number of ahtletes per event
        filteredEvents.forEach(function (event) {
            var numberAthlete = 0;
            for (let n = 0; n < athleteSignUps.length; n++) {
                if (athleteSignUps[n].EventId == event.EventId) {
                    if (filteredAthletes.filter((e) => { return e.AthleteId === athleteSignUps[n].AthleteId ? e : null; }).length > 0)
                        numberAthlete++;
                }
            }
            event.numberAthletes = numberAthlete;
            if (numberAthlete > largestSizeOfEvent)
                largestSizeOfEvent = numberAthlete;
        }
        );

        // Make sure max size can fit the largest partipated event
        let maxSize = maxRows > largestSizeOfEvent ? maxRows : largestSizeOfEvent;

        let dropInCol = 0;
        let totalInCol = 0;
        do {
            for (let i = 0; i < filteredEvents.length; i++) {
                let event = filteredEvents[i];
                if (event.numberAthletes + totalInCol > maxSize) {
                    console.log({ dropInCol, totalInCol });
                    dropInCol++;
                    totalInCol = 0;

                }
                totalInCol += event.numberAthletes;

                // Add in an allowance for the header
                totalInCol += 2;

                if (displayOptions.showQualifiers === true)
                    totalInCol += 1;

                event.column = dropInCol;
            }
            if (dropInCol < 4)
                break;

            maxSize += 10;
            dropInCol = 0;
            totalInCol = 0;
            console.log({ maxSize });


        } while (true);

        const cols = [4];

        cols[0] = { data: filteredEvents.filter((event) => event.column === 0), id: 0 };
        cols[1] = { data: filteredEvents.filter((event) => event.column === 1), id: 1 };
        cols[2] = { data: filteredEvents.filter((event) => event.column === 2), id: 2 };
        cols[3] = { data: filteredEvents.filter((event) => event.column === 3), id: 3 };

        return (
            <div className="report">
                <PageTitle team={objData.team} meet={objData.meet} />
                <DisplayOptions displaychange={displayChange} currentDisplayOptions={displayOptions} printerFriendly={displayOptions.printerFriendly} showingTimes={true} />
                <DisplayFilters displaychange={displayChange} currentDisplayOptions={displayOptions} divisions={objData.team.Divisions} printerFriendly={displayOptions.printerFriendly} />
                <div className="reportSection reportGroup">
                    {
                        cols.map((colNum) => {
                            return (
                                <div className="reportCol span_3_of_12" key={colNum.id}>
                                    {
                                        colNum.data.map((event) => {
                                            return <EventBox key={event.EventId} event={event} athleteList={filteredAthletes} qualifiers={objData.qualifiers} currentDisplayOptions={displayOptions} athleteSignUps={filteredSignUps.filter((ath) => ath.EventId == event.EventId)} />
                                        })
                                    }
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        )
    }
}

function EventBox(props) {

    let orderedAthleteSignUps = props.athleteSignUps.map((a) => {
        let obj = new AthleteEventSignUp();
        obj.fromObject(a)

        const fullAthlete = props.athleteList.filter((fullAth) => fullAth.AthleteId === obj.AthleteId)[0];

        //if (obj.record === 0 && fullAthlete.Records !== undefined && fullAthlete.Records.length > 0) {

        if (fullAthlete.Records !== undefined && fullAthlete.Records.length > 0) {
            const item = fullAthlete.Records.filter((record) => record.Event.EventId == props.event.EventId);
            if (item !== null && item.length > 0) {
                obj.Record = item[0].BaseResult;
                obj.RecordType = item[0].RecordTypeDesc;
            }

        }

        return obj;
    });

    let timed = props.event.EventTypeId === 0;

    orderedAthleteSignUps = orderedAthleteSignUps.sort((a, b) => a.compare(timed, b));

    // athlete list is filtered to those to display
    // based on athlete list get the list of age groups in use

    let divisionsInUse = []

    props.athleteList.forEach((e) => {
        if (divisionsInUse.includes(parseInt(e.AgeGroupId)) === false) {
            divisionsInUse.push(parseInt(e.AgeGroupId));
        }
    })

    console.log({ quals: props.qualifiers, divisionsInUse });

    let matchedQualifiers = [];

    if (props.currentDisplayOptions.showQualifiers === true && props.event.EventId != "8" && props.event.EventId != "14")
        matchedQualifiers = props.qualifiers.filter((qual) => (divisionsInUse.includes(qual.DivisionId)) && qual.Events !== undefined && qual.Events.filter((e) => e.EventResult.Event.EventId == props.event.EventId).length == 1);

    console.log({ matchedQualifiers })

    return (
        <div className="reportBox">
            <h3>{props.event.Name + " (" + orderedAthleteSignUps.length + ")"}</h3>
            <table>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>SR</td>
                        <td>Note</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        orderedAthleteSignUps.map((ath) => {
                            const athleteEventSignUp = new AthleteEventSignUp();
                            athleteEventSignUp.fromObject(ath);
                            //athleteEventSignUp.EventId = ath.id;
                            //athleteEventSignUp.Name = ath.Name;
                            //athleteEventSignUp.AthleteId = ath.AthleteId;
                            //athleteEventSignUp.Record = ath.Record;
                            //athleteEventSignUp.Notes = ath.Notes;
                            const fullAthlete = props.athleteList.filter((fullAth) => fullAth.AthleteId === ath.AthleteId)[0];

                            // this section relaces athletes record with the record as of the date of event
                            /*
                            if (ath.Record === 0 && fullAthlete.Records !== undefined && fullAthlete.Records.length > 0) {
                                const item = fullAthlete.Records.filter((record) => record.Event.EventId == props.event.EventId);
                                if (item !== null && item.length > 0) {
                                    //athleteEventSignUp.Result = item[0].BaseResult;
                                    //athleteEventSignUp.RecordType = item[0].RecordTypeDesc;
                                }
 
                            }
                            */
                            let athleteName = fullAthlete.AlsoKnownAs === null || fullAthlete.AlsoKnownAs === "" ? fullAthlete.Name : fullAthlete.AlsoKnownAs;

                            if (props.currentDisplayOptions.switchNames === true) {
                                let n = athleteName.indexOf(",");
                                if (n > 0)
                                    athleteName = athleteName.substring(n + 1) + " " + athleteName.substring(0, n);
                            }

                            return (
                                <tr key={fullAthlete.AthleteId}>
                                    <td>{athleteName}</td>
                                    <td>{(athleteEventSignUp.RecordType === 'PR' ? "*" : "") + athleteEventSignUp.resultText(props.event)}</td>
                                    <td>{athleteEventSignUp.Notes}</td>
                                </tr>
                            )
                        }
                        )
                    }
                </tbody>
                <tfoot>
                    {
                        matchedQualifiers.map((qual) => {
                            return (<tr key={qual.DivisionId}>
                                <td>{qual.Description}</td>
                                <td>{qual.Events.filter((e) => e.EventResult.Event.EventId == props.event.EventId)[0].EventResult.Result} </td>
                                <td>**</td>
                            </tr>)
                        })

                    }

                </tfoot>
            </table>
        </div>
    )
}



export default EventListView;
/*
return (
    <tr key={qual.DivisionId}>
        <td>{qual.Description}</td>
    </tr>
)
*/

/*
                        props.qualifiers.filter((qual) => divisionsInUse.includes(qual.DivisionId)).map((qual) => {
                            return (<tr key={qual.DivisionId}>
                                <td>{qual.Description}</td>
                                <td>{qual.Events.filter((e) => e.EventResult.Event.EventId == props.event.EventId)[0] == null ? "empty" : qual.Events.filter((e) => e.EventResult.Event.EventId == props.event.EventId)[0].EventResult.Result} </td>
                            </tr>)
                        })
*/