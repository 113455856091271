import React, { Component } from 'react'
import '../assets/reportfilters.css'

class DisplayFilters extends Component {
    constructor(props) {
        super(props);
        console.log({ props: props });

        this.state = {
            formOpen: false,
            ageGroups: props.divisions,
        };

        this.switchDisplay = this.switchDisplay.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.actionitem = this.props.displaychange;

    }

    switchDisplay() {
        if (this.state.formOpen === true)
            this.actionitem({ ageGroups: this.state.ageGroups });
        this.setState({ formOpen: !this.state.formOpen });
    }

    handleChange(event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        let oldValues = this.state.ageGroups;
        oldValues.filter((e) => e.GenderDescription === event.target.name).forEach((x) => x.checked = value);
        this.setState({ ageGroups: oldValues });
    }

    render() {
        console.log({ renderingApp: this.state })
        if (this.props.printerFriendly === true)
            return null;

        if (this.state.formOpen === true)
            return (
                <div className="form-popupLHS" id="myForm">
                    <form className="form-container">
                        <label><b>Options</b></label>
                        <hr />
                        {
                            this.state.ageGroups.map((ageGroup) => {
                                return (
                                    <div key={ageGroup.AgeGroupId}>
                                        <input type="checkbox" name={ageGroup.GenderDescription} checked={ageGroup.checked} onChange={this.handleChange} />
                                        <label >{ageGroup.GenderDescription}</label>
                                    </div>
                                )
                            }
                            )
                        }
                        <button type="button" className="btn cancel" onClick={this.switchDisplay}>Apply</button>
                    </form>
                </div>
            );
        else
            return (
                <div className="form-popupLHS" id="myForm">
                    <form className="form-container">
                        <button type="button" className="btn cancel" onClick={this.switchDisplay}>Age Groups</button>
                    </form>
                </div>
            )
    }
}

export default DisplayFilters;