import React, { useState } from 'react'
import '../assets/reportfilters.css'
import DisplayOptionsContext from "../components/DisplayOptionsContext.js"


const DisplayOptions = function (props) {
    let [formOpen, setFormOpen] = useState(false);

    const [displayOptions, setDisplayOptions] = React.useContext(DisplayOptionsContext).displayOptionsStore

    let switchDisplay = () => {
        if (formOpen === true)
            props.displaychange(displayOptions);
        setFormOpen(!formOpen);
    }

    let handleChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setDisplayOptions({ ...displayOptions, [event.target.name]: value });
    }


    if (displayOptions.printerFriendly === true)
        return null;

    if (formOpen === true)
        return (
            <div className="form-popup" id="myForm">
                <form className="form-container">
                    <label><b>Options</b></label>
                    <hr />

                    <label htmlFor="paticipatingEventsOnly">Participating events</label>
                    <input type="checkbox" name="paticipatingEventsOnly" checked={displayOptions.paticipatingEventsOnly} onChange={handleChange} />
                    <br></br>

                    {props.showingTimes === false ?
                        <React.Fragment >
                            <label htmlFor="ahtleteOut">Include absent</label>
                            <input type="checkbox" name="athleteOut" checked={displayOptions.athleteOut} onChange={handleChange} />
                            <br></br>

                            <label htmlFor="showConferenceId">Conference Id</label>
                            <input type="checkbox" name="showConferenceId" checked={displayOptions.showConferenceId} onChange={handleChange} />
                            <br></br>

                            <label htmlFor="ahtleteTotals">Athlete totals</label>
                            <input type="checkbox" name="athleteTotals" checked={displayOptions.athleteTotals} onChange={handleChange} />
                            <br></br>
                            <label htmlFor="conferenceOrder">Conf Id Order</label>
                            <input type="checkbox" name="conferenceOrder" checked={displayOptions.conferenceOrder} onChange={handleChange} />
                            <br></br>

                            <label htmlFor="showPRs">Show PR</label>
                            <input type="checkbox" name="showPRs" checked={displayOptions.showPRs} onChange={handleChange} />
                            <br></br>

                            <label htmlFor="showComments">Show Comments</label>
                            <input type="checkbox" name="showComments" checked={displayOptions.showComments} onChange={handleChange} />
                            <br></br>
                        </React.Fragment> :
                        <React.Fragment >
                        </React.Fragment>
                    }
                    <label htmlFor="showQualifiers">Show Qualifiers</label>
                    <input type="checkbox" name="showQualifiers" checked={displayOptions.showQualifiers} onChange={handleChange} />
                    <br></br>


                    <label htmlFor="printerFriendly">Printer Friendly</label>
                    <input type="checkbox" name="printerFriendly" checked={displayOptions.printerFriendly} onChange={handleChange} />
                    <br></br>

                    <label htmlFor="switchNames">Switch Names</label>
                    <input type="checkbox" name="switchNames" checked={displayOptions.switchNames} onChange={handleChange} />
                    <br></br>



                    <button type="button" className="btn cancel" onClick={switchDisplay}>Apply</button>
                </form>
            </div>
        );
    else
        return (
            <div className="form-popup" id="myForm">
                <form className="form-container">
                    <button type="button" className="btn cancel" onClick={switchDisplay}>Display Options</button>
                </form>
            </div>
        )
}

export default DisplayOptions;