import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { connect } from 'react-redux'
import FirestoreModel from '../../../../firestoreModel'

function mapStateToProps(state) {
  return ({
    userAuth: state.userAuth
  });
}

function mapDispatchToProps(dispatch) {

  return {
    onSetTeam: (Team) => {
      console.log({ team: Team });
      dispatch({ type: 'TEAM_SET', Team });
    },
    onSignIn: (userAuth) => {
      dispatch({ type: 'SIGNED_IN', userAuth });
    },
    onSignOut: (userAuth) => {
      dispatch({ type: 'SIGNED_OUT', userAuth });
    }

  };

}




const UserActions = connect(mapStateToProps, mapDispatchToProps)(function (props) {
  let [state, setState] = useState({ visible: false });

  let toggleUserActions = (action) => {
    const newState = !state.visible;
    setState({
      visible: newState
    });

  }

  let validateEmail = () => {
    const fireModel = new FirestoreModel();
    const auth = fireModel.firebase.auth();

    debugger;

    if (auth == null)
      return;

    auth.currentUser.sendEmailVerification()
      .then(() => {
        alert('Email is sent please check your emails and your SPAM folder.')
        // Email verification sent!
        // ...
      });
  }

  let signInOrOut = (action) => {
    let signedIn = !(props.userAuth === undefined || props.userAuth === null)

    if (signedIn) {
      if (props.userAuth.uid === null)
        signedIn = false;
    }

    if (signedIn)
      // do some log out
      props.onSignOut();
    else
      // do some logic
      toggleUserActions();
  }


  let signedIn = !(props.userAuth === undefined || props.userAuth === null)

  if (signedIn) {
    if (props.userAuth.uid === null)
      signedIn = false;
  }

  console.log({ ua: props.userAuth })

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <img
          className="user-avatar rounded-circle mr-2"
          src={signedIn === true ? require("./../../../../images/avatars/1.png") : require("./../../../../images/avatars/0.png")}
          alt="User Avatar"
        />{" "}
        <span className="d-none d-md-inline-block">{signedIn ? props.userAuth.email : 'Unknown'}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={state.visible}>

        {
          signedIn === true ? <DropdownItem tag={Link} to="TeamDashboard">  <i className="material-icons">&#xE7FD;</i> Teams</DropdownItem> : ''
        }
        {
          signedIn === true && props.userAuth.emailVerified === false ? <DropdownItem onClick={() => { validateEmail() }}><i className="material-icons">&#xE2C7;</i> Validate Email</DropdownItem> : ''
        }
        <DropdownItem tag={Link} to="TeamDashboard">
          <i className="material-icons">&#xE896;</i> Reset Password
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to={signedIn ? "/" : "/TeamDashboard"} className="text-danger" onClick={signInOrOut}>
          <i className="material-icons text-danger">&#xE879;</i> {signedIn ? 'Logout' : 'Sign-In'}
        </DropdownItem>

        {signedIn === false ? <DropdownItem tag={Link} to="/TeamDashboard" className="text-danger" onClick={signInOrOut}>
          <i className="material-icons text-danger">&#xE879;</i>Register
        </DropdownItem> : ''
        }
      </Collapse>
    </NavItem>
  )
}
)

export default UserActions;