export default function () {
  return [

    {
      title: "Welcome",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: "",
      to: "/mainpage",
    },
    {
      title: "Privacy Policy",
      to: "/privacy",
      htmlBefore: '<i class="material-icons">vertical_split</i>',

    },
    {
      title: "Tutorials",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/tutorial",
    },
    {
      title: "Contact Us",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/support",
    },
    {
      title: "Team Dashboard",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/TeamDashboard",
    }
  ];
}
